import React from "react";
import { Link } from "gatsby";
import darkImage from "../img/logo2.svg";
import lightImage from "../img/logo-white.svg";
import DarkLightSwitch from "../components/DarkLightSwitch";
import "../font-awesome-4.7.0/css/font-awesome.min.css";
import RecipesLink from "./RecipesLink";
import SearchForm from "../components/SearchForm"
import kickstartericon from "../img/kickstarter.svg"

const Navbar = class extends React.Component {
  state = { open: false, logo: darkImage };
  componentDidMount() {
    const theme = localStorage.getItem("theme");
    const logoImage = !theme || theme === "light" ? darkImage : lightImage;
    this.setState({ logo: logoImage });
  }
  handleThemeSwitch(theme) {
    const logoImage = theme === "light" ? darkImage : lightImage;
    this.setState({ logo: logoImage });
  }
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img
                  src={this.state.logo}
                  alt="Neederland"
                  style={{ width: "188px" }}
                />
              </Link>
              {/* Hamburger menu */}
              <div
                role='menu'
                tabIndex={0} 
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
                onKeyDown={this.handleKeyDown}
              >
                <span />
                <span />
                <span />
                <span class="arrow-up"></span>
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-start has-text-centered">
                <Link
                  className="navbar-item"
                  to="/about/"
                  activeClassName="active"
                >
                 <span className="tabActive">About</span>
                </Link>
                <Link
                  className="navbar-item"
                  to="/services/"
                  activeClassName="active"
                >
                  <span className="tabActive">Services</span>
                </Link>
                <Link
                  className="navbar-item"
                  to="/products/"
                  activeClassName="active"
                >
                  <span className="tabActive">Products</span>
                </Link>
                <RecipesLink to="/blog/" className="navbar-item">
                  <span className="tabActive">Blog</span>
                </RecipesLink>
                <Link
                  className="navbar-item"
                  activeClassName="active"
                  to="/contact/"
                >
                  <span className="tabActive">Contact</span>
                </Link>
              </div>
              <div className="navbar-end has-text-centered">
              <SearchForm />
                 <i className="navbar-item">
                  <DarkLightSwitch
                    handleThemeSwitch={this.handleThemeSwitch.bind(this)}
                  />
                </i>
                
                <a
                  className="navbar-item"
                  href="https://www.etsy.com/uk/listing/966515318/headphone-stand-airpods-max-and-any"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                  <img src={kickstartericon} alt="Kickstarter" />
                </span>
                
                </a>
              </div>
            </div>
          </div>
        </nav>
    );
  }
};

export default Navbar;
