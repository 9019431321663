import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import Collapsible from 'react-collapsible'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div className="layout-page">
        <div>{children}</div>
        <div className="cookieCollapsible">
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
declineButtonText="Decline"
          flipButtons
          cookieName="gatsby-gdpr-google-analytics"
          buttonStyle={{
            
            backgroundColor: '#05ce78',
            color: '#FFFFFF',
            display: 'block',
            padding: '6px 12px 6px',
            fontSize: '1rem',
            lineHeight: '1.25',
            borderRadius: '.25rem',
            textDecoration: 'none',
            fontWeight: '500',
            textAlign: 'center',
            margin: '2px',
            marginTop: '5px',
            marginBottom: '5px',
            marginRight: '12px',
            }}
          declineButtonStyle={{
            backgroundColor: 'rgb(124, 124, 124)',
            color: '#FFFFFF',
            display: 'block',
            padding: '6px 10px 6px',
            fontSize: '1rem',
            lineHeight: '1.25',
            borderRadius: '.25rem',
            textDecoration: 'none',
            fontWeight: '500',
            textAlign: 'center',
            margin: '2px',
            marginBottom: '5px',
            marginTop: '5px',
            }}
          contentStyle={{
            flex: '1 1 60vw',
            maxWidth: '1350px',
}}
      style={{
            background: "rgba(21, 31, 40, 0.83)",
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignContent: 'center',
            width: '26vw',
            minWidth: '350px',
            left: 'none',
            right: '0px',
            }}
          containerClasses="alert alert-warning col-lg-12"
        >

<Collapsible  trigger="Cookie consent"><p>
          This website stores cookies on your device.
          These cookies are used
          to collect information about how you interact with this website and
          allow us to remember you.
          <br></br>We use this information in order to improve and customize
          your browsing experience and for analytics and metrics about our
          visitors on this website.
          <br></br>
          To find out more details, see our:<br/>
          <Link className="privacyPolicyLink" to="/privacy-policy/">
                  Privacy Policy
                </Link>
          <br></br>
          If you decline, your information won’t be tracked when you visit this
          website. A single cookie will be used in your browser to remember your
          preference not to be tracked.</p>
        </Collapsible>
        </CookieConsent>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default TemplateWrapper
