import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo-white.svg'
import ShareButtons from './ShareButtons'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
        <a href="/" >
        <img
            src={logo}
            alt="Neederland"
            style={{ width: '18em', height: '3em' }}
          />
        </a>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw', marginBottom: '55px'}}>
              <div className="menu-list">
                <Link to="/" className="navbar-item">
                  Home
                </Link>
                <span>｜</span>
                <Link className="navbar-item" to="/about/">
                  About
                </Link>
                <span>｜</span>
                <Link className="navbar-item" to="/products/">
                  Products
                </Link>
                <span>｜</span>
                <Link className="navbar-item" to="/blog/">
                  Latest Stories
                </Link>
                <span>｜</span>
                <Link className="navbar-item" to="/privacy-policy/">
                  Privacy Policy
                </Link>
                <span>｜</span>
                <Link className="navbar-item" to="/contact/">
                  Contact
                </Link>
              </div>

              <div className="menu-list-bottom">
                <div>
                  Neederland Ltd is a company registered in England and Wales
                  (Company No. 12755188) Office 506B, 182-184 High Street North, East Ham, London E6 2JA
                </div>
                <ShareButtons style={{ padding: `2em` }} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
