import React from 'react'
import facebook from '../img/share/facebook.svg'
import twitter from '../img/share/twitter.svg'
import youtube from '../img/share/youtube.svg'
import instagram from '../img/share/instagram.svg'
import linkedin from '../img/share/linkedin.svg'



class ShareButtons extends React.Component {
  render() {

    return (
      <div style={this.props.style} className="social">
        <a target="_blank" title="facebook" href="https://www.facebook.com/neederland" rel="noreferrer">
          <img
            src={facebook}
            alt="Facebook"
            className="fas fa-lg"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a target="_blank" title="twitter" href="https://twitter.com/neederland" rel="noreferrer">
          <img
            className="fas fa-lg"
            src={twitter}
            alt="Twitter"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a target="_blank" title="youtube" href="https://www.youtube.com/neederland" rel="noreferrer">
          <img
            className="fas fa-lg"
            src={youtube}
            alt="Youtube"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a target="_blank" title="instagram" href="https://www.instagram.com/neederland/" rel="noreferrer">
          <img
            className="fas fa-lg"
            src={instagram}
            alt="Instagram"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a target="_blank" title="linkedin" href="https://www.linkedin.com/company/neederland/" rel="noreferrer">
          <img
            className="fas fa-lg"
            src={linkedin}
            alt="Linkedin"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
       
      </div>
    )
  }
}

export default ShareButtons
