// components/Nav/RecipesLink.js
import React from 'react'
import { Link } from 'gatsby'

const invalidPaths = ['/about/', '/contact/', '/products/', '/', '/privacy-policy/', '/products/truestand/', '/products/audioswitch/', '/services/', '/services/Product-Development/', '/services/Custom-Lego-Kits/', '/services/Custom-Packaging/']

const isActive = (className) => ({ location }) => {
  const activeClassName = { className: `${className} active` }

  if (!invalidPaths.includes(location.pathname)) return activeClassName
  return { className }
}

const RecipesLink = ({ className, children, ...props }) => (
  <Link getProps={isActive(className)} {...props}>
    {children}
  </Link>
)

export default RecipesLink
